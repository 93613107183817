(function() {
    var buildPattern, buildString, replace;
  
    buildPattern = function(str) {
      str = str.replace(/\\/g, '\\\\');
      return str.replace(/([\(\)\{\}\^\$\*\+\?\,\.\:\!\<\>\=\|\[\]\-])/g, '\\$1');
    };
  
    buildString = function(key, data, arg) {
      var type;
      type = typeof data[key];
      if (type === 'function') {
        if (typeof arg !== 'undefined') {
          return data[key].call(data, arg);
        } else {
          return data[key].call(data);
        }
      }
      if (type === 'undefined') {
        return 'undefined';
      }
      if (data[key] === null) {
        return 'null';
      }
      return data[key].toString();
    };
  
    replace = function(str, data) {
      var functionMatch, functionPattern, varMatch, varPattern;
      varPattern = /#\{([^#]+?)\}/g;
      functionPattern = /^(.+?)\((.*)\)$/;
      if (varPattern.test(str)) {
        varPattern.lastIndex = 0;
        functionPattern.lastIndex = 0;
        varMatch = varPattern.exec(str);
        if (functionPattern.test(varMatch[1])) {
          functionMatch = functionPattern.exec(varMatch[1]);
          return replace(str.replace(new RegExp(buildPattern(varMatch[0]), 'g'), buildString(functionMatch[1], data, functionMatch[2])), data);
        } else {
          return replace(str.replace(new RegExp(buildPattern(varMatch[0]), 'g'), buildString(varMatch[1], data)), data);
        }
      } else {
        return str;
      }
    };
  
    String.prototype.coffeeReplace = function(data) {
      return replace(this, data);
    };
    
  }).call(this);